<script setup>
import { copyVerses } from "@/composables/useApp";

const props = defineProps(["verseMenu", "verseModel", "verseMenuCoords", "selection"]);
const emit = defineEmits(["update:verseMenu", "select", "copy", "share"]);

function handleMouseDown() {
  emit("update:verseMenu", false);
}

function copy() {
  emit("copy");
  emit("close");
}
</script>
<template>
  <v-menu
    :modelValue="verseMenu"
    @update:modelValue="emit('update:verseMenu', $event)"
    :coords="verseMenuCoords"
    origins="top right"
    :autosize="false"
    class="menu-el"
  >
    <template #default="{ close }">
      <div class="menu-items-list">
        <div
          class="menu-item-el px-4 py-4"
          @mousedown.stop="
            handleMouseDown();
            emit('select');
          "
        >
          <img src="/icons/select-svgrepo-com2.svg" class="svg-icon" />
          <span class="ml-2">{{ transl("Select") }}</span>
        </div>
        <div
          class="menu-item-el px-4 py-4"
          @mousedown.stop="
            handleMouseDown();
            emit('copy');
          "
        >
          <img src="/icons/copy-icon.svg" alt="coppy icon" class="svg-icon" />
          <span class="ml-2" @click="copy()">{{ transl("Copy") }}</span>
        </div>
        <div
          class="menu-item-el px-4 py-4"
          @mousedown.stop="
            handleMouseDown();
            emit('share');
          "
        >
          <img src="/icons/share-ios-black.svg" alt="share icon" class="svg-icon" />
          <span class="ml-2"> {{ transl("Share") }}</span>
        </div>
      </div>
    </template>
  </v-menu>
</template>
<style scoped>
.menu-el {
  position: absolute;
  top: 0;
  left: 0;
}
.menu-items-list {
}
.menu-item-el {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
}

.menu-item-el:hover {
  background-color: rgba(246, 246, 246, 0.518);
}

.svg-icon {
  width: 25px;
}
</style>
